import { ActionButton, Button, Flex, Grid, Header, Text, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { useIsSSR } from '@react-aria/ssr';
import { useBreakpoint } from '@react-spectrum/utils';
import { FocusableRefValue } from '@react-types/shared';
import Add from '@spectrum-icons/workflow/Add';
import Light from '@spectrum-icons/workflow/Light';
import Moon from '@spectrum-icons/workflow/Moon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

interface Props {
  children: ReactNode;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
}

const Layout = ({
  children,
  isDarkMode,
  setIsDarkMode
}: Props) => {
  const { matchedBreakpoints } = useBreakpoint()!;
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');
  const isSSR = useIsSSR();
  const router = useRouter();

  const postAJobLinkRef = useRef<FocusableRefValue<HTMLAnchorElement> | null>(null);

  const [headerDropShadowIsVisible, setHeaderDropShadowIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;

      if (position === 0) {
        setHeaderDropShadowIsVisible(false);
      } else if (!headerDropShadowIsVisible) {
        setHeaderDropShadowIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerDropShadowIsVisible]);

  useEffect(() => {
    if (isSSR) return;

    const listener = (event: MouseEvent) => {
      event.preventDefault();
    };

    const postAJobLink = postAJobLinkRef.current!.UNSAFE_getDOMNode()!;

    postAJobLink.addEventListener('click', listener);

    return () => {
      postAJobLink.removeEventListener('click', listener);
    };
  }, [isSSR]);

  const handlePostAJobLinkPress = () => {
    router.push('/post-a-job');
  };

  if (isSSR) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Grid
      minHeight="100vh"
      rows={{
        base: ['size-700', '1fr'],
        S: ['size-1000', '1fr']
      }}
    >
      <Header
        UNSAFE_style={{
          backgroundColor: 'var(--spectrum-global-color-gray-100)',
          boxShadow: headerDropShadowIsVisible ? '0px var(--spectrum-alias-dropshadow-offset-y) var(--spectrum-alias-dropshadow-blur) var(--spectrum-alias-dropshadow-color)' : undefined,
          transition: 'box-shadow 0.2s linear',
          zIndex: 2
        }}
        position="sticky"
        top={0}
      >
        <Flex
          UNSAFE_style={{
            ...isInBaseBreakpointRange ? {
              paddingLeft: 'var(--spectrum-global-dimension-size-100)',
              paddingRight: 'var(--spectrum-global-dimension-size-100)'
            } : {
              paddingLeft: 'var(--spectrum-global-dimension-size-200)',
              paddingRight: 'var(--spectrum-global-dimension-size-200)'
            }
          }}
          alignItems="center"
          height="100%"
          marginX={{ S: 'auto' }}
          maxWidth={{ S: 1280 }}
        >
          <Link
            href="/"
            passHref
          >
            <a
              className="link"
              {...!isInBaseBreakpointRange && { style: { marginLeft: -10 } }}
            >
              <Flex
                alignItems={{ S: 'center' }}
                columnGap={{ S: 'size-100' }}
                marginX={{ S: 'size-125' }}
                marginY={{ S: 'size-50' }}
              >
                <svg
                  height={40}
                  viewBox="0 0 600 600"
                  width={40}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M195.227 450.922c-34.66-6.816-89.69 10.371-121.21-3.031-14.953-6.352-26.44-30-28.8-48.484-4.118-32.47-1.965-100.22 1.519-133.34 1.332-12.633 5.719-29.961 9.09-45.461 6.972-32.09 14.125-49.527 40.906-62.121 13.793-6.485 38.266-10.605 59.098-12.125 35.113-2.559 71.746 1.203 107.57-1.52 11.8-.894 23.262-5.168 34.848-6.066 15.574-1.2 29.3.727 42.43 0 26.656-1.477 49.594-6.148 74.258-7.578 2.445-.14 2.566-1.281 4.543-1.512 76.805-9.223 145.11 9.078 151.52 77.273 1.601 17.051-.57 36.82-1.52 54.551-.879 16.527-2.625 34.355-4.547 51.52-3.734 33.402-4.363 67.406-19.699 92.422-6.52 10.633-15.32 15.32-28.789 22.73-11.328 6.235-22.629 14.711-33.336 18.176-26.18 8.5-62.883 9.094-96.969 9.094-33.37 0-68.027 2.57-98.488 1.52-12.527 10.374-26.68 25.66-40.914 42.425-11.109 13.086-24.602 40.902-46.96 34.86-27.552-7.446-3.993-58.435-4.555-83.333zm337.9-159.09c.454-24.605 2.547-87.152-7.582-103.04-11.777-18.465-58.762-31.64-95.457-28.789-24.387 1.898-54.438 7.492-83.332 9.09-16.457.918-33.059-1.3-50.004 0-9.32.723-18.016 3.848-27.273 4.547-11.289.855-26.94-1.528-37.883-1.512-17.023.016-40.117 3.086-60.62 4.547-20.798 1.484-47.48-.676-62.122 4.547-15.227 5.437-19.332 19.312-22.727 37.883-4.832 26.434-5.761 59.484-4.546 86.363 1.796 39.664-8.282 84.28 6.058 112.13 32.492 8.398 120.79-16.566 134.86 9.094 6.262 11.395-1.11 26.695 1.52 46.96 16.699-7.492 17.94-30.776 36.359-37.882 3.617-1.399 7.414.672 12.125 0 4.574-.645 8.082-3.536 12.125-4.547 25.676-6.485 51.328-7.016 81.828-10.605 17.574-2.07 35.738-6.367 53.027-7.582 16.297-1.14 32.195 3.992 46.973 3.031 22.785-1.477 42.777-16.055 59.09-22.727 6.816-28.051 6.898-64.844 7.586-101.51z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
                <Text
                  UNSAFE_className="u-font-family-acme"
                  UNSAFE_style={{
                    color: 'var(--spectrum-alias-heading-text-color)',
                    fontSize: 'var(--spectrum-global-dimension-font-size-600)'
                  }}
                  isHidden={{ base: true, S: false }}
                  marginTop={{ S: 'size-50' }}
                >
                  {'Comics Jobs'}
                </Text>
              </Flex>
            </a>
          </Link>
          <Button
            ref={postAJobLinkRef}
            elementType="a"
            href="/post-a-job"
            marginEnd={{ base: 'size-100', S: 'size-200' }}
            marginStart="auto"
            onPress={handlePostAJobLinkPress}
            variant="cta"
          >
            <Add size="S" />
            <Text>
              {'Post a job'}
            </Text>
          </Button>
          <TooltipTrigger delay={0}>
            <ActionButton
              aria-label={isDarkMode ? 'Switch to light theme' : 'Switch to dark theme'}
              onPress={() => {
                const newIsDarkMode = !isDarkMode;

                localStorage.setItem('isDarkMode', `${newIsDarkMode}`);

                document.documentElement.style.setProperty('color-scheme', newIsDarkMode ? 'dark' : 'light');

                setIsDarkMode(newIsDarkMode);
              }}
            >
              {isDarkMode ? <Light /> : <Moon />}
            </ActionButton>
            <Tooltip>
              {isDarkMode ? 'Switch to light theme' : 'Switch to dark theme'}
            </Tooltip>
          </TooltipTrigger>
        </Flex>
      </Header>
      {children}
    </Grid>
  );
};

export default Layout;
